/* eslint-disable @typescript-eslint/no-unused-vars */
import { Autocomplete, Box, Button, createFilterOptions, InputAdornment, TextField } from "@mui/material";
import dayjs from "dayjs";
import { Currency } from "dinero.js";
import { MRT_Row } from "material-react-table";
import moment from "moment";
import React, { DependencyList, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import MonetaryInput from "src/Components/Common/MonetaryInput";
import { isOverFlowing } from "src/Components/Common/SideBar";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { Dialog } from "src/Components/Dialog/Dialog";
import { ColDef } from "src/Components/ReactTable/ReactTable";
import ListBusinessPartnerUsers from "src/Components/Recon360/PartnerMaster/ListBusinessPartnerUsers";
import TaskAttachmentsModal from "src/Components/Recon360/Tickets/TaskAttachmentsModal";
import { NAKAD_SERVICE } from "src/Components/Recon360/Tickets/TasksHistoryModal";
import { BusinessPartnerListForBcBeta } from "src/entity/recon-entity/ReconInterfaces";
import LoadingGif from "src/Graphics/loading.gif";
import { CounterState } from "src/slices/partnerCommunication/bulkMailsSlice";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { Currencies } from "src/Utils/Common/Constants";
import { filterDateBetweenStartAndEndDate } from "src/Utils/DateUtils";
import { DefaultCurrency, formatMoney, ToDineroObj } from "src/Utils/MoneyUtils";
import { BalanceConfirmationBetaColorMap, BalanceConfirmationBetaStatuses } from "src/Utils/PartnerCommunication";
import { MuiSliderFilterSteps } from "../../BalanceConfirmationBeta/BalanceConfirmationBeta";
import { DEFAULT_BRANCH_CODE, DEFAULT_COMPANY_ID, StateDispatch } from "../../CommonLegacy/CommonComponents";
import BcNewContext from "../Components/BcNewContext";

const filter = createFilterOptions<any>();

type AllMailsTabProps = {
  allMailsDeps: DependencyList;
  setAllMailsDefs: StateDispatch<ColDef<BusinessPartnerListForBcBeta>[]>;
  type: "allMails";
};

type RecTabProps = {
  recMailsDeps: DependencyList;
  setRecMailsDefs: (data: ColDef<BusinessPartnerListForBcBeta>[], hiddenColumns: string[]) => void;
  type: "recMails";
};

type NotRecTabProps = {
  notRecMailsDeps: DependencyList;
  setNotRecMailsDefs: (data: ColDef<BusinessPartnerListForBcBeta>[], hiddenColumns: string[]) => void;
  type: "notRecMails";
};

type MailingTabProps = {
  mailingDeps: DependencyList;
  setMailingDefs: (data: ColDef<BusinessPartnerListForBcBeta>[], hiddenColumns: string[]) => void;
  type: "mailing";
};

interface ColumnDefinitionsProps {
  props: AllMailsTabProps | RecTabProps | NotRecTabProps | MailingTabProps;
}

const ColumnDefinitions: React.FC<ColumnDefinitionsProps> = ({ props }) => {
  const { actor } = useContext(userContext);
  const { companyId, branchCode, rowsDataBcBeta, setRowsDataBcBeta, storeAllCategories, isSyncingMailStatus } =
    useContext(BcNewContext);

  const { isSendingBulkMailsBCB } = useSelector((state: any) => state.bulkMailsStatus as CounterState);

  const ASYNC_INTERVAL_OPs_IN_PROGRESS = isSendingBulkMailsBCB || isSyncingMailStatus;

  // ListBusinessPartnerUsers
  const [openListBusinessPartnerUsers, setOpenListBusinessPartnerUsers] = useState<boolean>(false);
  const storeRowOpenListBusinessPartnerUsers = useRef<MRT_Row<BusinessPartnerListForBcBeta>>(null);
  const [categoryLoader, setCategoryLoader] = useState<boolean>(false);

  //   closing balance and closing balance date crop
  //   const storeClosingBalanceValueFlag = useRef<boolean>(false);
  const storeClosingBalanceDateFlag = useRef<boolean>(false);
  const storeClosingBalanceRowID = useRef<number>(null);
  const [storeClosingBalanceDateValue, setStoreClosingBalanceDateValue] = useState<any>(null);

  // Attachments
  const currentSelectedTaskDetailsForAttachments = useRef<{
    businessPartnerName: string;
    taskId: number;
    mappingId: number;
  }>(null);
  const [showTaskAttachmentsModal, setShowTaskAttachmentsModal] = useState<boolean>(false);

  // Tally CB async
  const [bulkTallyCbDate, _setBulkTallyCbDate] = useState<string>("");
  //   const [defaultTallyCbDate, setDefaultTallyCbDate] = useState<string>("");
  const [updatingTallyCbDate, _setUpdatingTallyCbDate] = useState(false);
  const [loadingTallyCbAll, _setLoadingTallyCbAll] = useState(false);
  //   const lastTallyInterval = useRef(null);

  let finalDeps = [];

  if (props.type === "allMails") {
    finalDeps = [
      ...props.allMailsDeps,
      actor,
      rowsDataBcBeta,
      updatingTallyCbDate,
      bulkTallyCbDate,
      loadingTallyCbAll,
      ASYNC_INTERVAL_OPs_IN_PROGRESS,
    ];

    useEffect(() => {
      props?.setAllMailsDefs(columnDefinitionAllMailsTab);
    }, finalDeps);
  }

  const FilterOtherColumns = (
    allColumns: ColDef<BusinessPartnerListForBcBeta>[],
    colsToShow: ColDef<BusinessPartnerListForBcBeta>[]
  ) => {
    return allColumns.filter((col) => {
      const colId = col.id || col.header;
      return colsToShow.findIndex((item) => item.header === colId || item.id === colId) === -1;
    });
  };

  const sortByOrder = (arr: ColDef<BusinessPartnerListForBcBeta>[], order: ColDef<BusinessPartnerListForBcBeta>[]) =>
    arr.sort(
      (a, b) =>
        order.findIndex((item) => (item.id || item.header) === (a.id || a.header)) -
        order.findIndex((item) => (item.id || item.header) === (b.id || b.header))
    );

  if (props.type === "recMails") {
    finalDeps = [...props.recMailsDeps, actor, rowsDataBcBeta, ASYNC_INTERVAL_OPs_IN_PROGRESS];

    useEffect(() => {
      // sort columnDefinitionAllMailsTab based on how it is in columnDefinitionRecName
      const columnDefinitionRecTab = sortByOrder(columnDefinitionAllMailsTab, columnDefinitionRecName);
      const hiddenColumns = FilterOtherColumns(columnDefinitionAllMailsTab, columnDefinitionRecName);

      props?.setRecMailsDefs(
        columnDefinitionRecTab,
        hiddenColumns.map((col) => col.id || col.header)
      );
    }, finalDeps);
  }

  if (props.type === "notRecMails") {
    finalDeps = [...props.notRecMailsDeps, actor, rowsDataBcBeta, ASYNC_INTERVAL_OPs_IN_PROGRESS];

    useEffect(() => {
      const columnDefinitionNotRecTab = columnDefinitionAllMailsTab;
      const hiddenColumns = FilterOtherColumns(columnDefinitionAllMailsTab, columnDefinitionNotRecName);

      props?.setNotRecMailsDefs(
        columnDefinitionNotRecTab,
        hiddenColumns.map((col) => col.id || col.header)
      );
    }, finalDeps);
  }

  if (props.type === "mailing") {
    finalDeps = [...props.mailingDeps, actor, rowsDataBcBeta, ASYNC_INTERVAL_OPs_IN_PROGRESS];

    useEffect(() => {
      const columnDefinitionMailing = columnDefinitionAllMailsTab;
      const hiddenColumns = FilterOtherColumns(columnDefinitionAllMailsTab, columnDefinitionMailName);

      props?.setMailingDefs(
        columnDefinitionMailing,
        hiddenColumns.map((col) => col.id || col.header)
      );
    }, finalDeps);
  }

  const columnDefinitionAllMailsTab = useMemo(
    (): ColDef<BusinessPartnerListForBcBeta>[] => [
      {
        header: "Business Partner",
        id: "Business partner name",
        sticky: "left",
        enableSorting: true,
        filterVariant: "autocomplete",
        accessorFn: (row) => row.businessPartnerName?.toLowerCase() || "",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden_anchor">
            <a
              onClick={() => {
                storeRowOpenListBusinessPartnerUsers.current = row;
                setOpenListBusinessPartnerUsers(true);
              }}
              title={row.original.businessPartnerName}
            >
              {row.original.businessPartnerName}
            </a>
          </div>
        ),
        size: 300,
      },
      {
        header: "Vendor Code",
        accessorFn: (row) => row?.vendorCode || "",
        enableSorting: true,
        size: 200,
        filterVariant: "autocomplete",
      },
      {
        header: "Category",
        accessorFn: (row) => row.category?.join(", ") || "",
        size: 200,
        filterVariant: "multi-select",
        filterFn: "contains",
        Cell: ({ row }) => (
          <div className="category_ui" style={{ width: "100%" }}>
            <Autocomplete
              limitTags={1}
              value={row.original.category !== null && row.original.category?.length > 0 ? row.original.category : []}
              onChange={(_ev, val) => {
                let value = val as string[];
                if (value !== null && value?.length > 0) {
                  if (value[value.length - 1]?.includes("+Add => ")) {
                    value[value.length - 1] = value[value.length - 1].replace('+Add => "', "");
                    value[value.length - 1] = value[value.length - 1].replace('"', "");
                    const removeEmptyVal = value?.filter((el) => {
                      return el !== "";
                    });
                    value = removeEmptyVal;
                  }
                }
                storeAllCategories.current = [...new Set([...storeAllCategories.current, ...value])];
                updateOwnAndBusinessPartnerMappingCategories(row, value);
              }}
              noOptionsText={"Enter a new category"}
              multiple={true}
              id="tags-outlined"
              options={storeAllCategories?.current}
              getOptionLabel={(option) => option}
              filterSelectedOptions={true}
              filterOptions={(_, params) => {
                const filtered = filter(
                  storeAllCategories?.current === null ? [] : storeAllCategories?.current,
                  params
                );
                // Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push(`+Add => "${params.inputValue.trim()}"`);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  // placeholder="Add"
                  sx={{
                    "& input": {
                      px: "12px !important",
                    },
                  }}
                />
              )}
              sx={{
                "&, & div": {
                  borderTopLeftRadius: "12px",
                  borderTopRightRadius: "12px",
                },
                "& fieldset": {
                  borderWidth: "0.4px",
                  borderRadius: "12px",
                },
              }}
              loading={categoryLoader}
            />
          </div>
        ),
      },
      {
        header: "Email Status",
        accessorFn: (row) => row?.status || "",
        size: 280,
        filterVariant: "multi-select",
        Cell: ({ row }) => (
          <>
            {row.original.status ? (
              <div className="vertical_center_align gap_10" style={{ width: "100%" }}>
                <Button
                  onClick={() => {
                    // uiLogger(uiLoggerName.ui_DownloadLedgerStatus);
                  }}
                  style={{
                    width: "100%",
                    overflowWrap: "break-word",
                    color: BalanceConfirmationBetaColorMap[row.original.status]?.color || "#000",
                    backgroundColor: BalanceConfirmationBetaColorMap[row.original.status]?.bgColor || "#D7D7D7",
                  }}
                  className="status_theme_btn"
                  disabled={true}
                >
                  {row.original.status === BalanceConfirmationBetaStatuses.ReminderSent
                    ? row.original.balanceConfirmationStatusWithReminderCount || row.original.status
                    : row.original.status === BalanceConfirmationBetaStatuses.EscalationSent
                    ? row.original.escalationSentStatusWithReminderCount || row.original.status
                    : row.original.status}
                </Button>
              </div>
            ) : (
              <img src={LoadingGif} alt="Loading.." className="loading_gif" />
            )}
          </>
        ),
      },
      {
        id: "lastStatusChangeDate",
        header: "Last Status Change date",
        accessorFn: (row) => new Date(row.lastStatusChangeDate || null),
        enableSorting: true,
        minSize: 280,
        size: 350,
        maxSize: 360,
        filterVariant: "date-range",

        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.lastStatusChangeDate);
          }
        },
        Cell: ({ row }) =>
          row.original.lastStatusChangeDate
            ? moment(row.original.lastStatusChangeDate)?.format("DD-MM-YYYY")
            : (row.original.lastStatusChangeDate as any),
      },
      {
        id: "closingBalanceDate",
        header: "Closing Balance date",
        Header: () => (
          <div style={{ font: "inherit" }}>
            Closing Balance date
            {/* {actor.integration && (
              <div className="textField_height">
                <TextField
                  key="bulkCBDate"
                  type="date"
                  size="small"
                  disabled={updatingTallyCbDate}
                  defaultValue={bulkTallyCbDate || defaultTallyCbDate}
                  // onChange={(_e) => setBulkTallyCbDate(_e.target.value)}
                  onBlur={(_e) => {
                    setBulkTallyCbDate(_e.target.value);
                    if (_e.target.value && bulkTallyCbDate !== _e.target.value)
                      UpdateErpClosingBalanceForAllBp(_e.target.value);
                  }}
                  sx={{
                    div: { borderColor: "white !important", borderRadius: 16 },
                    input: { background: "white", p: "1px 8px", borderRadius: 16 },
                  }}
                  onClick={(_e) => _e.stopPropagation()}
                />
                {(updatingTallyCbDate || loadingTallyCbAll) && (
                  <CircularProgress className="ml_10" size={18} color="inherit" sx={{ "svg *": { strokeWidth: 4 } }} />
                )}
              </div>
            )} */}
          </div>
        ),
        accessorFn: (row) => new Date(row.closingBalanceDate || null),
        enableSorting: true,
        minSize: 280,
        size: 350,
        maxSize: 360,
        filterVariant: "date-range",

        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.closingBalanceDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textField_height">
            <TextField
              key={row.original?.closingBalanceDate?.toString()}
              variant="outlined"
              disabled={
                ASYNC_INTERVAL_OPs_IN_PROGRESS ||
                (actor.integration
                  ? loadingTallyCbAll
                  : row.original.status !== BalanceConfirmationBetaStatuses.RequestBalanceConfirmation)
              }
              // placeholder="yyyy-mm-dd"
              type="date"
              size="small"
              defaultValue={
                storeClosingBalanceDateFlag.current &&
                storeClosingBalanceRowID.current === row.original.businessPartnerId
                  ? storeClosingBalanceDateValue !== null
                    ? moment(storeClosingBalanceDateValue)?.format("YYYY-MM-DD")
                    : storeClosingBalanceDateValue
                  : row.original.closingBalanceDate !== null
                  ? moment(row.original.closingBalanceDate)?.format("YYYY-MM-DD")
                  : row.original.closingBalanceDate
              }
              onClick={() => {
                storeClosingBalanceDateFlag.current = true;
                storeClosingBalanceRowID.current = row.original.businessPartnerId;
                setStoreClosingBalanceDateValue(row.original.closingBalanceDate);
              }}
              onChange={(e) => {
                setStoreClosingBalanceDateValue(e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  //   if (actor.integration === true) {
                  //     // updateClosingBalanceByClosingBalanceDate(row, e.target.value);
                  //     UpdateErpClosingBalance(row, e.target.value);
                  //     setBulkTallyCbDate("");
                  //   } else
                  UpdateClosingBalanceDate(row, e.target.value);
                }
              }}
            />
          </div>
        ),
      },
      {
        id: "ownClosingBalance",
        header: "Closing Balance Own",
        Header: () => (
          <div style={{ font: "inherit" }}>
            Closing Balance Own
            {/* {(updatingTallyCbDate || loadingTallyCbAll) && (
              <CircularProgress className="ml_10" size={18} color="inherit" sx={{ "svg *": { strokeWidth: 4 } }} />
            )} */}
          </div>
        ),
        accessorFn: (row) => (isNaN(Number(row.ownClosingBalance)) ? 0 : Number(row.ownClosingBalance)),
        enableSorting: true,
        size: 250,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) => (
          <div className="textField_height">
            <MonetaryInput
              key={row.original.ownClosingBalance}
              returnNull={true}
              value={
                !isNaN(parseFloat(row.original.ownClosingBalance?.toString()))
                  ? parseFloat(row.original.ownClosingBalance?.toString())
                  : row.original.ownClosingBalance
              }
              // placeholder={row.original.ownClosingBalance as string}
              setValue={(value) => {
                if (row.original.ownClosingBalance === null && value === null) return;
                UpdateClosingBalance(row, value);
              }}
              disabled={
                ASYNC_INTERVAL_OPs_IN_PROGRESS ||
                actor.integration ||
                row.original.status !== BalanceConfirmationBetaStatuses.RequestBalanceConfirmation
              }
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" sx={{ "&>*": { fontSize: "13px !important", pt: "2px" } }}>
                    {Currencies.find((cur) => cur.code === row.original.currency)?.code || DefaultCurrency.INR}
                  </InputAdornment>
                ),
              }}
              sx={{
                "input::placeholder": {
                  fontSize: "14px",
                },
              }}
              currency={(row.original.currency as Currency) || "INR"}
            />
          </div>
        ),
      },
      {
        header: "Partner Replied on Mail",
        id: "lastPartnerReplyDate",
        accessorFn: (row) => new Date(row.lastPartnerReplyDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.lastPartnerReplyDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.lastPartnerReplyDate ? moment(row.original.lastPartnerReplyDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        header: "CB Partner",
        accessorFn: (row) => Number(row.businessPartnerClosingBalance) || 0,
        enableSorting: true,
        size: 200,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) => (
          <div className="vertical_center_align" style={{ paddingRight: 24 }}>
            <Box ml={"auto"}>
              {row.original.businessPartnerClosingBalance === null
                ? null
                : formatMoney(
                    ToDineroObj(
                      row.original.businessPartnerClosingBalance,
                      (row.original.currency as Currency) || "INR"
                    )
                  )}
            </Box>
          </div>
        ),
      },
      {
        header: "CB difference",
        accessorFn: (row) => Number(row.closingBalanceDifference) || 0,
        enableSorting: true,
        size: 200,
        filterVariant: "range",
        filterFn: "betweenInclusive",
        muiFilterSliderProps: ({ column }) => MuiSliderFilterSteps(column),
        Cell: ({ row }) => (
          <div className="vertical_center_align" style={{ paddingRight: 24 }}>
            <Box ml={"auto"}>
              {row.original.closingBalanceDifference === null
                ? null
                : formatMoney(
                    ToDineroObj(row.original.closingBalanceDifference, (row.original.currency as Currency) || "INR")
                  )}
            </Box>
          </div>
        ),
      },
      {
        header: "Attachments",
        accessorFn: (row) => (row.ledgers || row.signedPdf || row.openItems ? "true" : "false"),
        size: 200,
        filterVariant: "checkbox",
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {(row.original.signedPdf || row.original.ledgers || row.original.openItems) && row.original.ticketId ? (
              <Button
                className="theme_btn"
                size="small"
                onClick={() => {
                  currentSelectedTaskDetailsForAttachments.current = {
                    businessPartnerName: row.original.businessPartnerName,
                    taskId: row.original.ticketId,
                    mappingId: row.original.mappingId,
                  };
                  setShowTaskAttachmentsModal(true);
                }}
              >
                VIEW
              </Button>
            ) : (
              "-"
            )}
          </div>
        ),
      },
      // delete individual download columns as attachment column added
      {
        header: "Issue Raised",
        accessorFn: (row) => row.issueRaised || "",
        size: 200,
        filterVariant: "select",
      },
      {
        id: "responseStatus",
        header: "Response Status",
        accessorFn: (row) => row.statusOverview || "",
        size: 240,
        filterVariant: "select",
      },
      {
        header: "Response Approved",
        accessorFn: (row) => row.approvedStatus || "",
        size: 240,
        filterVariant: "select",
        Cell: ({ row }) => <div className="textOverflow_hidden">{row.original.approvedStatus}</div>,
      },
      {
        header: "Approved Date",
        accessorFn: (row) => new Date(row.approvedAt || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.approvedAt);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.approvedAt ? moment(row.original.approvedAt)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        id: "communicationType",
        header: "Communication Type",
        accessorFn: (row) => row.communicationTypeName || "",
        size: 240,
        filterVariant: "select",
      },
      {
        header: "Additional Remarks",
        accessorFn: (row) => row.remarks || "",
        size: 240,
        filterVariant: "text",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit", font: "inherit" }}
          >
            {row.original.remarks ? row.original.remarks : ""}
          </div>
        ),
      },
      {
        header: "Task ID",
        accessorFn: (row) => row.ticketId?.toString() || "",
        id: "ticketId",
        size: 180,
        filterVariant: "autocomplete",
      },
      {
        header: "Partner ID",
        accessorFn: (row) => row.businessPartnerId?.toString() || "",
        id: "businessPartnerId",
        size: 180,
        filterVariant: "autocomplete",
      },
      {
        header: "Relationship",
        accessorFn: (row) => row?.relationship || "",
        id: "relationship",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit" }}
          >
            {row.original.relationship ? row.original.relationship : ""}
          </div>
        ),
        size: 220,
        minSize: 220,
        filterVariant: "multi-select",
        filterFn: "equals",
      },
      {
        header: "Email Initiation Date",
        id: "emailInitiationDate",
        accessorFn: (row) => new Date(row.emailInitiationDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.emailInitiationDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.emailInitiationDate ? moment(row.original.emailInitiationDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        header: "Partner Response Date",
        id: "responseSubmittedDate",
        accessorFn: (row) => new Date(row.responseSubmittedDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.responseSubmittedDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.responseSubmittedDate
              ? moment(row.original.responseSubmittedDate)?.format("DD-MM-YYYY")
              : "-"}
          </div>
        ),
      },
      {
        header: "Team Reply Date",
        id: "teamReplyDate",
        accessorFn: (row) => new Date(row.lastTeamReplyDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.lastTeamReplyDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.lastTeamReplyDate ? moment(row.original.lastTeamReplyDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        header: "Team Mail Sent Date",
        id: "teamMailSentDate",
        accessorFn: (row) => new Date(row.lastTeamMailSentDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.lastTeamMailSentDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.lastTeamMailSentDate ? moment(row.original.lastTeamMailSentDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        id: "taskLabel",
        header: "Task Label",
        accessorFn: (row) => row.label || "",
        size: 240,
        filterVariant: "text",
        Cell: ({ row }) => (
          <div
            className="textOverflow_hidden"
            ref={(th) => {
              if (th && isOverFlowing(th)) th.title = th.innerText;
            }}
            style={{ width: "inherit", font: "inherit" }}
          >
            {row.original.label ? row.original.label : ""}
          </div>
        ),
      },
      {
        id: "escalationStartDate",
        header: "Escalation Start Date",
        accessorFn: (row) => new Date(row.escalationStartDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.escalationStartDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.escalationStartDate ? moment(row.original.escalationStartDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
      {
        id: "escalationReplyDate",
        header: "Escalation Reply Date",
        accessorFn: (row) => new Date(row.escalationReplyDate || null),
        size: 350,
        filterVariant: "date-range",
        // custom filter function
        filterFn: (row, _id, filterValue, _meta) => {
          const minDate = filterValue[0] ? dayjs(filterValue[0]).toDate() : null;
          const maxDate = filterValue[1] ? dayjs(filterValue[1]).toDate() : null;
          if (minDate || maxDate) {
            return filterDateBetweenStartAndEndDate(minDate, maxDate, row.original.escalationReplyDate);
          }
        },
        Cell: ({ row }) => (
          <div className="textOverflow_hidden">
            {row.original.escalationReplyDate ? moment(row.original.escalationReplyDate)?.format("DD-MM-YYYY") : "-"}
          </div>
        ),
      },
    ],
    // eslint-disable-next-line
    [finalDeps]
  );

  const columnDefinitionRecName = useMemo(
    (): ColDef<BusinessPartnerListForBcBeta>[] => [
      {
        header: "Business Partner",
        id: "Business partner name",
      },
      {
        header: "Vendor Code",
      },
      {
        header: "Category",
      },
      {
        header: "Email Status",
      },
      {
        header: "Partner Response Date",
        id: "responseSubmittedDate",
      },
      {
        header: "Partner Replied on Mail",
        id: "lastPartnerReplyDate",
      },
      {
        header: "Issue Raised",
      },
      {
        header: "Additional Remarks",
      },
      {
        header: "Attachments",
      },
    ],
    [rowsDataBcBeta]
  );

  const columnDefinitionNotRecName = useMemo(
    (): ColDef<BusinessPartnerListForBcBeta>[] => [
      {
        header: "Business Partner",
        id: "Business partner name",
      },
      {
        header: "Vendor Code",
      },
      {
        header: "Category",
      },
      {
        header: "Email Status",
      },
      {
        header: "Partner Replied on Mail",
        id: "lastPartnerReplyDate",
      },
      {
        header: "Issue Raised",
      },
    ],
    [rowsDataBcBeta]
  );

  const columnDefinitionMailName = useMemo(
    (): ColDef<BusinessPartnerListForBcBeta>[] => [
      {
        header: "Business Partner",
        id: "Business partner name",
      },
      {
        header: "Vendor Code",
      },
      {
        header: "Category",
      },
      {
        id: "closingBalanceDate",
        header: "Closing Balance date",
      },
      {
        id: "ownClosingBalance",
        header: "Closing Balance Own",
      },
    ],
    []
  );

  const UpdateClosingBalance = (row: MRT_Row<BusinessPartnerListForBcBeta>, value: number) => {
    useFetch<{ message: string }>(API_ENDPOINTS.UPDATE_CLOSING_BALANCE.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_CLOSING_BALANCE.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        businessPartnerId: row.original.businessPartnerId,
        mappingId: row.original.mappingId,
        closingBalance: value,
      },
      thenCallBack: (_res) => {
        row.original.ownClosingBalance = value;
      },
    });
  };

  const UpdateClosingBalanceDate = (row: MRT_Row<BusinessPartnerListForBcBeta>, value: string) => {
    useFetch<{ message: string }>(API_ENDPOINTS.UPDATE_CLOSING_BALANCE_DATE.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_CLOSING_BALANCE_DATE.failureMessage,
      showSuccessToast: true,
      data: {
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
        businessPartnerId: row.original.businessPartnerId,
        mappingId: row.original.mappingId,
        closingBalanceDate: value,
      },
      thenCallBack: (_res) => {
        row.original.closingBalanceDate = value as any;
        storeClosingBalanceDateFlag.current = false;
      },
    });
  };

  // Updates Own and Bp Categories for each row on Blur
  const updateOwnAndBusinessPartnerMappingCategories = async (
    row: MRT_Row<BusinessPartnerListForBcBeta>,
    value: string[]
  ) => {
    useFetch(API_ENDPOINTS.UPDATE_OWN_AND_PARTNER_MAPPING_CATEGORIES.url, "POST", {
      failureMessage: API_ENDPOINTS.UPDATE_OWN_AND_PARTNER_MAPPING_CATEGORIES.failureMessage,
      data: {
        ownId: actor.id,
        businessPartnerId: row.original.businessPartnerId,
        mappingId: row.original.mappingId,
        category: value,
        companyId: companyId || DEFAULT_COMPANY_ID,
        branchCode: branchCode || DEFAULT_BRANCH_CODE,
      },
      thenCallBack: (_res) => {
        setCategoryLoader(true);
        // row.original.category = value;
        const found = rowsDataBcBeta.find((v) => v.businessPartnerId === row.original.businessPartnerId);

        if (found) found.category = value;
        setRowsDataBcBeta([...rowsDataBcBeta]);
        // updateCategory(row, value);
      },
    });
  };

  return (
    <>
      {/* ListBusinessPartnerUsers */}
      <Dialog
        open={openListBusinessPartnerUsers}
        onClose={() => setOpenListBusinessPartnerUsers(true)}
        width70Per={true}
      >
        <ListBusinessPartnerUsers
          setOpenListBusinessPartnerUsers={setOpenListBusinessPartnerUsers}
          storeRowOpenListBusinessPartnerUsers={storeRowOpenListBusinessPartnerUsers as any}
          companyId={companyId}
          branchCode={branchCode}
        />
      </Dialog>
      {/* Attachments */}
      {showTaskAttachmentsModal && (
        <TaskAttachmentsModal
          open={showTaskAttachmentsModal}
          setOpen={setShowTaskAttachmentsModal}
          businessPartnerName={currentSelectedTaskDetailsForAttachments.current.businessPartnerName}
          ticketId={currentSelectedTaskDetailsForAttachments.current.taskId}
          companyId={companyId}
          branchCode={branchCode}
          serviceType={NAKAD_SERVICE.PartnerCommunication}
          mappingId={currentSelectedTaskDetailsForAttachments.current.mappingId}
        />
      )}
    </>
  );
};

export default ColumnDefinitions;
