import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Box, FormControl, IconButton, MenuItem, Select, Switch, TextField, Typography } from "@mui/material";
import React, { useContext, useState } from "react";
import useFetch from "src/Components/Common/useFetch";
import { userContext } from "src/Components/Contexts/userContext";
import { ClientType } from "src/entity/models/FrontendActor";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { TicketStatuses } from "../../Tickets/CurrentTicket";
import DeleteStatusConfirmationModal from "./DeleteStatusConfirmationModal";
import { CategoryWiseStatuses, StatusItem } from "./WorkflowSettings";

interface StatusSectionProps {
  statuses: CategoryWiseStatuses;
  setStatuses: React.Dispatch<React.SetStateAction<CategoryWiseStatuses>>;
  status: StatusItem;
  category: string;
  actionOwnerOptions: string[];
  setIsAddingNewStatus: React.Dispatch<React.SetStateAction<boolean>>;
  getAllStatuses: () => void;
}
export default function StatusSection({
  statuses,
  setStatuses,
  status,
  category,
  setIsAddingNewStatus,
  actionOwnerOptions,
  getAllStatuses,
}: StatusSectionProps) {
  const { actor } = useContext(userContext);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isToggleActive, setIsToggleActive] = useState<boolean>(status.isActive);
  const [actionOwner, setActionOwner] = useState<string>(status.actionOwner);
  const [statusName, setStatusName] = useState<string>(status.status);
  const [statusNameError, setStatusNameError] = useState<string>("");
  const [showDeleteStatusConfirmationModal, setShowDeleteStatusConfirmationModal] = useState<boolean>(false);

  const handleActionOwnerChange = (value: string) => {
    useFetch(API_ENDPOINTS.UPDATE_WORKFLOW_STATUS(status.id).url, "PUT", {
      failureMessage: API_ENDPOINTS.UPDATE_WORKFLOW_STATUS(status.id).failureMessage,
      showSuccessToast: true,
      data: {
        actionOwner: value,
      },
      catchCallBack: () => {
        getAllStatuses();
      },
    });
  };

  const handleToggleChange = () => {
    useFetch(API_ENDPOINTS.TOGGLE_WORKFLOW_STATUS(status.id).url, "PUT", {
      failureMessage: API_ENDPOINTS.TOGGLE_WORKFLOW_STATUS(status.id).failureMessage,
      showSuccessToast: true,
      catchCallBack: () => {
        setIsToggleActive((prev) => !prev);
      },
    });
  };

  const handleDeleteStatus = () => {
    if (status.id === null) {
      setIsAddingNewStatus(false);
      setStatuses((prev) => ({
        ...prev,
        [category]: prev[category].filter((item) => item.id !== status.id),
      }));
    } else {
      setShowDeleteStatusConfirmationModal(true);
    }
  };

  const handleSaveEdit = (value: string) => {
    const trimmedValue = value.trim();

    const allNames = Object.values(statuses)
      .flat()
      .map((item) => item.id !== status.id && item.status.toLowerCase());
    let error = "";

    if (trimmedValue.length < 3) {
      error = "Minimum 3 characters required.";
    } else if (trimmedValue.length > 50) {
      error = "50 characters limit.";
    } else if (allNames.includes(value.toLowerCase())) {
      error = "Same status name cannot be used.";
    }

    if (error) {
      setStatusNameError(error);
      return;
    }
    if (status.id !== null) {
      useFetch(API_ENDPOINTS.UPDATE_WORKFLOW_STATUS(status.id).url, "PUT", {
        failureMessage: API_ENDPOINTS.UPDATE_WORKFLOW_STATUS(status.id).failureMessage,
        showSuccessToast: true,
        data: {
          status: value,
        },
        thenCallBack: () => {
          getAllStatuses();
        },
        catchCallBack: () => {
          getAllStatuses();
        },
      });
    } else {
      useFetch(API_ENDPOINTS.WORKFLOW_STATUS.url, "POST", {
        failureMessage: API_ENDPOINTS.WORKFLOW_STATUS.failureMessage,
        showSuccessToast: true,
        data: {
          status: value,
          category,
          actionOwner,
        },
        thenCallBack: () => {
          setIsAddingNewStatus(false);
          getAllStatuses();
        },
        catchCallBack: () => {
          setIsAddingNewStatus(false);
          getAllStatuses();
        },
      });
    }
    setIsEditing(false);
  };
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        paddingY: "8px",
      }}
    >
      <Box sx={{ flex: 1 }}>
        {isEditing || status.id === null ? (
          <TextField
            autoFocus
            sx={{
              minWidth: "300px",
              "& .MuiOutlinedInput-root": {
                borderRadius: "4px",
              },
            }}
            value={statusName}
            onFocus={() => {
              if (status.id === null) setIsAddingNewStatus(true);
            }}
            onChange={(e) => {
              setStatusName(e.target.value);
            }}
            onBlur={(e) => {
              handleSaveEdit(e.target.value);
            }}
            placeholder={status.isDefault ? status.defaultStatus : "Enter status name"}
            variant="outlined"
            size="small"
            error={!!statusNameError}
            helperText={statusNameError}
          />
        ) : (
          <Typography
            className="fw_400 fs_16"
            sx={{
              color:
                status.status === TicketStatuses.IssueInLedger ||
                status.status === TicketStatuses.ApproverRejected ||
                status.status === TicketStatuses.CheckerRejected ||
                status.status === TicketStatuses.TechnicalIssue
                  ? "#ef6c00ff"
                  : "#000000de",
            }}
          >
            {statusName}
          </Typography>
        )}
      </Box>
      {!status.isDefault && (
        <IconButton onClick={() => handleDeleteStatus()} size="small" sx={{ marginRight: 2 }}>
          <DeleteIcon fontSize="small" color="error" />
        </IconButton>
      )}
      {!(actor.clientType === ClientType.Product || actor.clientType === ClientType.ProductEnterprise) && (
        <FormControl className="update-custom-border-radius">
          <Select
            sx={{
              width: "200px",
            }}
            value={actionOwner}
            onChange={(e) => {
              setActionOwner(e.target.value as string);
              handleActionOwnerChange(e.target.value as string);
            }}
            size="small"
          >
            {actionOwnerOptions.map((option) => (
              <MenuItem value={option} key={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      <IconButton onClick={() => setIsEditing(true)} disabled={isEditing}>
        <EditIcon fontSize="small" />
      </IconButton>
      <Switch
        checked={isToggleActive}
        onChange={() => {
          setIsToggleActive((prev) => !prev);
          handleToggleChange();
        }}
      />
      {showDeleteStatusConfirmationModal && (
        <DeleteStatusConfirmationModal
          open={showDeleteStatusConfirmationModal}
          setOpen={setShowDeleteStatusConfirmationModal}
          statusId={status.id}
          getAllStatuses={getAllStatuses}
          statusName={status.status}
        />
      )}
    </Box>
  );
}
