import { AttachFile, Delete, DownloadRounded } from "@mui/icons-material";
import { Box, Button, Chip, Divider, Grid, Stack, Tooltip, Typography } from "@mui/material";
import { Buffer } from "buffer";
import { saveAs } from "file-saver";
import React, { Suspense, useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { uiLoggerNamesPreProcessingLedger } from "src/Utils/Recon/UiLogger/Constants";
import uiLogger from "src/Utils/UiLogger";
import { NdButton } from "../PartnerCommunication/MsmePartnerPortal/CommonComponents";
import ErrorPage from "../PartnerCommunication/MsmePartnerPortal/ErrorPage";
import { Header, LoadingPage, NavBar } from "../PartnerCommunication/MsmePartnerPortal/PartnerPortal.common";
import $ from "../PartnerCommunication/MsmePartnerPortal/PartnerPortal.module.scss";

// Define the type for route parameters
interface RouteParams {
  scriptName: string;
}

const LedgerPreprocessingPortalAction = {
  getSopHtml: "getSopHtml",
  runPythonScript: "runPythonScript",
};

const LedgerPreprocessingPortalJobStatus = {
  True: "True",
  False: "False",
};

const LedgerPreprocessingPortal = () => {
  const [files, setFiles] = useState([]);
  const history = useHistory();
  const [processLedgerLoader, setProcessLedgerLoader] = useState(false);
  const [pythonScriptName, setPythonScriptName] = useState("");
  const [sopHtml, setSopHtml] = useState("");
  // Use useParams to get the scriptName from the URL
  const { scriptName } = useParams<RouteParams>();
  const [verifiedCompany, setVerifiedCompany] = useState(false);
  const [clientName, setClientName] = useState("");
  const jobIdRef = useRef("");
  const [processedLedgerComplete, setProcessedLedgerComplete] = useState(false);
  const [downloadProcessedLedgerStart, setDownloadProcessedLedgerStart] = useState(false);
  const [uploadBanner, setUploadBanner] = useState([]);
  const [multiFileFlag, setMultiFileFlag] = useState([]);

  useEffect(() => {
    if (scriptName) {
      setPythonScriptName(scriptName); // Set the state to the script name from the URL
    }
    ledgerPreProcessing(LedgerPreprocessingPortalAction.getSopHtml, scriptName);
  }, []);

  const ledgerPreProcessing = (action, pythonScriptNameVal) => {
    const bodyFormData = new FormData();
    const fileStructure = []; // To store the indices structure

    bodyFormData.append("action", action);
    bodyFormData.append("pythonScriptName", pythonScriptNameVal);
    if (action === LedgerPreprocessingPortalAction.runPythonScript) {
      setProcessLedgerLoader(true);
      let currentIndex = 0; // Track the global index of files

      for (const fileArray of files) {
        const groupIndices = []; // To store indices for the current group
        for (const file of fileArray) {
          bodyFormData.append("files", file); // Append file to FormData
          groupIndices.push(currentIndex); // Add current index to the group
          currentIndex++; // Increment global index
        }
        fileStructure.push(groupIndices); // Add the group to fileStructure
      }
      // Append fileStructure to FormData as a JSON string
      bodyFormData.append("fileStructure", JSON.stringify(fileStructure));
      bodyFormData.append("uploadBanner", JSON.stringify(uploadBanner));
    } else {
      bodyFormData.append("files", null);
    }

    useFetch(API_ENDPOINTS.LEDGER_PREPROCESSING.url, "POST", {
      failureMessage: API_ENDPOINTS.LEDGER_PREPROCESSING.failureMessage,
      // showSuccessToast: true,
      data: bodyFormData,
      thenCallBack: (_res) => {
        if (_res.data.data.htmlData) {
          setSopHtml(_res.data?.data?.htmlData);
          setClientName(_res.data?.data?.companyName || "");
          // Update uploadBanner
          const newUploadBanner = _res.data?.data?.uploadBanner || [];
          setUploadBanner(newUploadBanner);

          // Initialize files array to match uploadBanner length
          setFiles(newUploadBanner.map(() => null));
          setVerifiedCompany(true);
          setMultiFileFlag(_res.data?.data?.multifileFlag || []);
        }
        if (_res.data.data.jobId && action === LedgerPreprocessingPortalAction.runPythonScript) {
          jobIdRef.current = _res.data?.data?.jobId;
          getLedgerPreprocessingJobStatus();
        }
      },
      catchCallBack: (_err) => {
        setProcessLedgerLoader(false);
        if (action === LedgerPreprocessingPortalAction.getSopHtml) {
          setTimeout(() => {
            history.push("/");
          }, 5000); // 5000 milliseconds = 5 seconds
        }
      },
    });
  };

  const getLedgerPreprocessingJobStatus = () => {
    useFetch(API_ENDPOINTS.GET_LEDGER_PREPROCESSING_JOB_STATUS.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_LEDGER_PREPROCESSING_JOB_STATUS.failureMessage,
      data: {
        jobId: jobIdRef.current,
      },
      thenCallBack: (_res) => {
        if (_res.data?.data?.jobStatus === LedgerPreprocessingPortalJobStatus.True) {
          setFiles([]);
          setProcessLedgerLoader(false);
          setProcessedLedgerComplete(true);
          toast.success(<CustomToast message="Ledger Processed Successfully" />);
        } else if (_res.data?.data?.jobStatus === LedgerPreprocessingPortalJobStatus.False) {
          setTimeout(() => {
            getLedgerPreprocessingJobStatus();
          }, 5000); // 5000 milliseconds = 5 seconds
        }
      },
      catchCallBack: (_err) => {
        setProcessLedgerLoader(false);
      },
    });
  };

  const downloadLedgerProcessedFile = () => {
    setDownloadProcessedLedgerStart(true);
    useFetch(API_ENDPOINTS.DOWNLOAD_LEDGER_PROCESSED_FILE.url, "POST", {
      failureMessage: API_ENDPOINTS.DOWNLOAD_LEDGER_PROCESSED_FILE.failureMessage,
      data: {
        jobId: jobIdRef.current,
        pythonScriptName: pythonScriptName,
      },
      thenCallBack: (_res) => {
        if (_res.data.data.base64ExcelOutput) {
          const excelData = Buffer.from(_res.data?.data?.base64ExcelOutput, "base64");
          const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          const blob = new Blob([excelData], { type: fileType });
          saveAs(blob, `${_res.data?.data?.fileName}`);
        }
        setDownloadProcessedLedgerStart(false);
      },
      catchCallBack: (_err) => {
        setProcessLedgerLoader(false);
        setDownloadProcessedLedgerStart(false);
      },
    });
  };
  const handleFileUpload = (filesArray: File[], index: number) => {
    const updatedFiles = [...files];
    updatedFiles[index] = filesArray; // Assign the array of files at the index
    setFiles(updatedFiles);
    setProcessedLedgerComplete(false); // Reset processed state
  };

  const handleFileDelete = (index) => {
    const updatedFiles = [...files];
    updatedFiles[index] = null;
    setFiles(updatedFiles);
  };
  return (
    <>
      <ErrorPage>
        <Suspense fallback={<LoadingPage />}>
          {verifiedCompany ? (
            <>
              <NavBar companyName={clientName || ""} companyLogo={""} />
              <Divider className={$.borderColor} />
              <Header headerText="Pre Processing Ledger" />
              <Divider className={$.borderColor} />
              <Box className="main_container">
                <Stack className="fade_in details_box" py={6} gap={5} maxWidth={900} mx={"auto"}>
                  <Box
                    className={$.BR_fix + " card_mail"}
                    sx={{
                      border: "1px solid #D7D7D7",
                    }}
                  >
                    <Stack sx={{ p: 2, gap: 1 }}>
                      <iframe
                        srcDoc={sopHtml}
                        referrerPolicy="no-referrer"
                        style={{
                          display: "block",
                          width: "100%",
                          height: "210px",
                          fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
                          color: "#000C",
                        }}
                      />
                    </Stack>
                  </Box>
                  <Suspense fallback={<LoadingPage noHeader={true} />}>
                    <Box className={$.uploader_box + " " + ""}>
                      {uploadBanner.map((bannerText, index) => (
                        <Grid key={`${bannerText}-${index}`} sx={{ borderBottom: "2px solid #D7D7D7" }}>
                          <Box className="vertical_center_align" p={2} gap={1}>
                            <Box className="left" mr={"auto"}>
                              <Typography variant="subtitle1" fontWeight={700} color={"#000"}>
                                {bannerText}
                              </Typography>
                              <Typography variant="body2">
                                Upload the Full Ledger in excel(.xlsx, .csv) format
                              </Typography>
                            </Box>

                            <Button
                              disabled={processLedgerLoader}
                              startIcon={<i className="fa-solid fa-arrow-up-from-bracket"></i>}
                              component="label" // Makes the button act like a label for the input
                              onClick={() => {
                                {
                                  false &&
                                    uiLogger(
                                      uiLoggerNamesPreProcessingLedger(clientName).UI_PPP_UPLOAD_CLICK.functionName, // This will dynamically get the function name based on client
                                      null,
                                      null,
                                      {
                                        message:
                                          uiLoggerNamesPreProcessingLedger(clientName).UI_PPP_UPLOAD_CLICK.message, // Static message
                                      }
                                    );
                                }
                              }}
                              sx={{ minWidth: 95 }}
                            >
                              Upload
                              <input
                                name="ConfirmDocUploader"
                                type="file"
                                hidden
                                accept=".xlsx, .csv"
                                multiple={multiFileFlag[index]}
                                onClick={(_e) => {
                                  (_e.target as HTMLInputElement).value = null; // Cast to HTMLInputElement
                                }}
                                onChange={(_e) => {
                                  const selectedFiles = Array.from(_e.target.files); // Always convert FileList to an array

                                  // Check if the number of selected files exceeds the limit (10 files)
                                  if (selectedFiles.length > 10) {
                                    toast.error(<CustomToast message="Can't upload more than 10 files" />);
                                    return;
                                  }

                                  if (selectedFiles.length > 0 && !isNaN(index)) {
                                    handleFileUpload(selectedFiles, index); // Always pass an array
                                  }
                                }}
                              />
                            </Button>
                          </Box>
                          {files[index] && (
                            <Box className="d_flex" sx={{ borderTop: "1px solid #D7D7D7" }} p={2} gap={1}>
                              <Chip
                                sx={{ mr: "auto", borderRadius: "8px", maxWidth: "85%" }}
                                variant="outlined"
                                label={
                                  <Tooltip
                                    title={
                                      <div>
                                        {files[index].map((file, idx) => (
                                          <div key={idx} style={{ fontSize: "12px" }}>
                                            {file.name}
                                          </div> // Each file name in a new row
                                        ))}
                                      </div>
                                    }
                                    arrow
                                  >
                                    <div className="vertical_center_align">
                                      <AttachFile fontSize="small" />
                                      <Typography
                                        style={{
                                          whiteSpace: "nowrap",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                        }}
                                        title={files[index].name}
                                      >
                                        {files[index][0].name}
                                        {files[index].length > 1 && (
                                          <span style={{ marginLeft: "15px", fontWeight: "bold" }}>
                                            +{files[index].length - 1} more
                                          </span>
                                        )}
                                      </Typography>
                                    </div>
                                  </Tooltip>
                                }
                              />
                              <Button
                                className={$.BR_fix}
                                variant="outlined"
                                startIcon={<Delete />}
                                disabled={processLedgerLoader}
                                color="error"
                                onClick={() => handleFileDelete(index)}
                              >
                                Delete
                              </Button>
                            </Box>
                          )}
                        </Grid>
                      ))}
                      <Box
                        className="right_align"
                        sx={{
                          borderTop: "1px solid #D7D7D7",
                          background: "#F3F3F3",
                          ":last-child": { borderRadius: "0 0 4px 4px" },
                        }}
                        p={2}
                      >
                        <NdButton
                          className={$.BR_fix}
                          variant="contained"
                          startIcon={<LoadingIcon loading={processLedgerLoader} />}
                          disabled={
                            files.length < 1 ||
                            processLedgerLoader ||
                            files.length !== uploadBanner.length ||
                            files.some((file) => file === null)
                          }
                          onClick={() => {
                            ledgerPreProcessing(LedgerPreprocessingPortalAction.runPythonScript, pythonScriptName);
                            {
                              false &&
                                uiLogger(
                                  uiLoggerNamesPreProcessingLedger(clientName).UI_PPP_PROCESS_CLICK.functionName, // This will dynamically get the function name based on client
                                  null,
                                  null,
                                  {
                                    message: uiLoggerNamesPreProcessingLedger(clientName).UI_PPP_PROCESS_CLICK.message, // Static message
                                  }
                                );
                            }
                          }}
                        >
                          Process Ledger
                        </NdButton>
                      </Box>
                    </Box>
                    {processedLedgerComplete && (
                      <Box
                        className="d_flex"
                        sx={{ border: "1px solid #2E7D32", background: "#EDF7ED", borderRadius: " 4px" }}
                        p={2}
                        gap={1}
                      >
                        <Box className="vertical_center_align" sx={{ mr: "auto" }}>
                          <Typography variant="subtitle1" sx={{ fontWeight: 700 }}>
                            Ledger Processed
                          </Typography>
                        </Box>

                        <Button
                          className={$.BR_fix}
                          variant="contained"
                          startIcon={
                            downloadProcessedLedgerStart ? (
                              <LoadingIcon loading={downloadProcessedLedgerStart} />
                            ) : (
                              <DownloadRounded />
                            )
                          }
                          disabled={downloadProcessedLedgerStart}
                          color="success"
                          onClick={() => {
                            downloadLedgerProcessedFile();
                            {
                              false &&
                                uiLogger(
                                  uiLoggerNamesPreProcessingLedger(clientName).UI_PPP_DOWNLOAD_CLICK.functionName, // This will dynamically get the function name based on client
                                  null,
                                  null,
                                  {
                                    message: uiLoggerNamesPreProcessingLedger(clientName).UI_PPP_DOWNLOAD_CLICK.message, // Static message
                                  }
                                );
                            }
                          }}
                        >
                          Download
                        </Button>
                      </Box>
                    )}
                  </Suspense>
                </Stack>
              </Box>
            </>
          ) : (
            <LoadingPage />
          )}
        </Suspense>
      </ErrorPage>
    </>
  );
};

export default LedgerPreprocessingPortal;
