/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from "react";

import { ChevronLeft, ChevronRight, Close, FilterList, FilterListOff } from "@mui/icons-material";
import { Box, Button, Card, IconButton, Paper, Stack, Step, StepLabel, Stepper, Tabs, Typography } from "@mui/material";
import useFetch from "src/Components/Common/useFetch";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import { PartnerCommsContext } from "../../../Context/PartnerCommunicationContext";
import { NdAsyncButton, NdButton, NdButtonOutlined } from "../../../MsmePartnerPortal/CommonComponents";
import FilterAutocomplete from "../../Common/FilterAutocomplete";
import BcNewContext from "../BcNewContext";
import BalanceConfirmationNewFilter, { keys } from "../BcNewFilter";
import ChooseCommsType from "./ChooseCommsType";
import SelectPartners from "./SelectPartners";
import SetupMail from "./SetupMail";

const Mailing = ({ setOpen }) => {
  const { activeStep, setActiveStep, mailingRows, setMailingRows } = useContext(BcNewContext);
  const { communicationTypeId, setCommunicationTypeId, taskLabel, setTaskLabel, setEscalationStarted } =
    useContext(PartnerCommsContext);

  const firstFoundCommType = mailingRows.find((row) => row.communicationTypeId);
  const allRowsMatched = mailingRows.every(
    (row) => row?.communicationTypeId !== firstFoundCommType?.communicationTypeId
  );

  const sendEmailRef = useRef<{ sendEmail: () => Promise<void> }>();

  const handlePageBack = () => {
    setOpen(false);
  };

  const handleBack = () => {
    if (firstFoundCommType && !allRowsMatched) {
      handlePageBack();
      return;
    }

    if (activeStep < 1) handlePageBack();
    else setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    if (activeStep >= 2) return;
    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    // Clean up Function: clear communication type id and task label and more
    return () => {
      setActiveStep(0);
      setCommunicationTypeId(null);
      setTaskLabel({ title: "" });
      setEscalationStarted(false);
      setMailingRows([]);
    };
  }, []);

  const GetTaskLabelValidForPartnerComms = async (label: string) => {
    return useFetch<{ isValid: boolean }>(API_ENDPOINTS.GET_TASK_LABEL_VALIDATION.url, "POST", {
      failureMessage: API_ENDPOINTS.GET_TASK_LABEL_VALIDATION.failureMessage,
      showSuccessToast: true,
      data: {
        businessPartnerData: mailingRows.map((row) => {
          return { businessPartnerId: row.businessPartnerId, mappingId: row.mappingId };
        }),
        taskLabel: label,
      },
      thenCallBack: (res) => {
        if (res.data?.isValid) {
          setTaskLabel({ title: label });
          handleNext();
        } else {
          setTaskLabel({ title: "" });
        }
      },
      catchCallBack: (_err) => {
        setTaskLabel({ title: "" });
      },
    });
  };

  return (
    <>
      <Stack gap={1}>
        <HeaderSection setOpen={setOpen} />

        {/* Main Content */}
        <Card
          className="d_flex"
          sx={{ minHeight: 260, flexFlow: "column", borderRadius: "8px !important", mb: "90px", bgcolor: "#FAFAFA" }}
        >
          {/* Choose Comms Type */}
          {activeStep === 0 && <ChooseCommsType />}
          {/* Select Partners */}
          {activeStep === 1 && <SelectPartners />}
          {/* Setup Email */}
          {activeStep === 2 && <SetupMail ref={sendEmailRef} handleBack={handleBack} handlePageBack={handlePageBack} />}
        </Card>

        {/* Bottom Navigation Bar */}
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            left: -2,
            width: "100%",
            boxShadow: "0px -2px 4px 0px rgba(0, 0, 0, 0.08)",
            background: "#fff",
          }}
        >
          <Stack
            direction={"row"}
            sx={{
              justifyContent: "space-between",
              maxWidth: "900px",
              marginX: "auto",
              padding: "24px",
            }}
          >
            <Stack sx={{ display: "flex", flexDirection: "row", gap: "16px", ml: "auto" }}>
              <Button color="inherit" onClick={handleBack} startIcon={<ChevronLeft />}>
                Back
              </Button>
              <Box sx={{ flex: "1 1 auto" }} />
              {activeStep < 1 && (
                <NdButton
                  variant="contained"
                  disabled={activeStep === 0 ? !communicationTypeId : mailingRows.length === 0}
                  onClick={handleNext}
                  endIcon={<ChevronRight />}
                >
                  Next
                </NdButton>
              )}
              {activeStep === 1 && (
                <NdAsyncButton
                  variant="contained"
                  disabled={mailingRows.length === 0}
                  onClick={async () => {
                    if (taskLabel?.title) await GetTaskLabelValidForPartnerComms(taskLabel?.title);
                    else handleNext();
                  }}
                  endIcon={<ChevronRight />}
                >
                  Next
                </NdAsyncButton>
              )}
              {activeStep === 2 && (
                <NdAsyncButton
                  variant="contained"
                  onClick={async () => {
                    // uiLogger(
                    //   uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_MAIL_SEND_CLICK.functionName,
                    //   companyId,
                    //   branchCode,
                    //   {
                    //     message: uiLoggerNamesPartnerCommunication.UI_PC_BCB_BULK_MAIL_SEND_CLICK.message,
                    //   }
                    // );
                    if (sendEmailRef?.current?.sendEmail) await sendEmailRef?.current.sendEmail();
                    // done sending emails
                  }}
                >
                  Send Email
                </NdAsyncButton>
              )}
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </>
  );
};

interface HeaderSectionProps {
  sx?: object;
  setOpen: (val: boolean) => void;
}

const HeaderSection: React.FC<HeaderSectionProps> = ({ sx, setOpen }) => {
  // Filter States
  const { storeAllCategories, activeStep, rowsDataBcNewMailing, storeRowsDataBcNewMailing, setRowsDataBcNewMailing } =
    useContext(BcNewContext);
  const [openFilter, setOpenFilter] = useState<boolean>(false);
  const [isFilterApplied, setIsFilterApplied] = useState<boolean>(false);
  const [appliedFilters, setAppliedFilters] = useState<Record<string, string[]>>({});
  const [reFilter, setReFilter] = useState<boolean>(false);
  const [filterObj, setFilterObj] = useState<{ [k in keys]: string[] }>(null);

  return (
    <Paper
      elevation={1}
      sx={{
        width: "100%",
        minWidth: "600px",
        height: "auto",
        borderRadius: "8px",
        ...sx,
      }}
    >
      {/* Top Area */}

      <Box className="vertical_center_align" sx={{ padding: 3, borderBottom: "1px solid #0002" }}>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mr: "auto" }}>
          <Typography variant="h5">New Mail</Typography>
          <Typography variant="body1">Send mail to single or bulk partner</Typography>
        </Box>

        {activeStep === 1 && (
          <NdButtonOutlined
            startIcon={isFilterApplied ? <FilterList /> : <FilterListOff />}
            onClick={() => setOpenFilter(true)}
            sx={{ mr: 1 }}
          >
            Filter
          </NdButtonOutlined>
        )}

        <IconButton onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </Box>

      {Object.keys(appliedFilters).length > 0 && (
        <Tabs
          value={false}
          onChange={() => null}
          variant="scrollable"
          scrollButtons="auto"
          sx={{ pt: "12px", pb: "20px" }}
        >
          <Stack direction="row" spacing={2} className="space_between" sx={{ px: 3 }}>
            <Box className="d_flex vertical_center_align">
              {Object.keys(appliedFilters).map((key) =>
                appliedFilters[key].length ? (
                  <Box sx={{ mr: 2 }} key={key}>
                    <FilterAutocomplete
                      filterName={key}
                      filteredValues={appliedFilters[key]}
                      setFilterObj={setFilterObj}
                      setReFilter={setReFilter}
                    />
                  </Box>
                ) : null
              )}
            </Box>
          </Stack>
        </Tabs>
      )}

      {/* Bottom Area */}
      <StepperSection />

      {/* Top Header Components */}
      <BalanceConfirmationNewFilter
        allCategories={storeAllCategories.current}
        storeRowsDataBalanceConfirmation={storeRowsDataBcNewMailing}
        rowsDataBalanceConfirmation={rowsDataBcNewMailing}
        setRowsDataBalanceConfirmation={setRowsDataBcNewMailing}
        setAppliedFilters={setAppliedFilters}
        setIsFilterApplied={setIsFilterApplied}
        openFilter={openFilter}
        setOpenFilter={setOpenFilter}
        filterObj={filterObj}
        setFilterObj={setFilterObj}
        reFilter={reFilter}
        setReFilter={setReFilter}
        newMailingConsumer={true}
      />
    </Paper>
  );
};

HeaderSection.defaultProps = {
  sx: {},
};

const steps = ["Select Communication Type", "Select Business Partners", "Setup Email"];

export const StepperSection = () => {
  const { activeStep } = useContext(BcNewContext);

  return (
    <Box sx={{ width: "100%", borderRadius: "8px", background: "#FAFAFA" }}>
      <Stepper activeStep={activeStep} sx={{ px: 3, py: 1.5 }}>
        {steps.map((label, _index) => {
          const stepProps: { completed?: boolean } = {};

          return (
            <Step key={label} {...stepProps}>
              <StepLabel>{label}</StepLabel>
            </Step>
          );
        })}
      </Stepper>
    </Box>
  );
};

export default Mailing;
