import React, { useState } from "react";
import { useHistory } from "react-router";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";
import Nakad from "../../Graphics/NAKAD_FINAL (1).png";
import LoadingIcon from "../Common/LoadingIcon";
import useFetch from "../Common/useFetch";
import "../Login/Login.scss";
import { NdButton } from "../Recon360/PartnerCommunication/MsmePartnerPortal/CommonComponents";
const ResetPassword = () => {
  const history = useHistory();
  const [email, setEmail] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);

  const update = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const resetPasswordHandler = async () => {
    setIsLoading(true);
    await useFetch(API_ENDPOINTS.RESET_API.url, "POST", {
      failureMessage: API_ENDPOINTS.RESET_API.failureMessage,
      data: {
        email,
      },
      thenCallBack: (response) => {
        setIsLoading(false);
        alert(response.data.message);
        setEmail("");
        history.push("/login");
      },
      catchCallBack: (error) => {
        alert(error.response.data.message);
        setEmail("");
        history.push("/reset");
        setIsLoading(false);
      },
    });
  };

  return (
    <div className="resetpass_wrapper">
      <div>
        <img src={Nakad} alt="" className="resetlogo" />
        <>
          <p style={{ marginTop: "125px" }} className="enter-info-text">
            Please enter registered user email id
          </p>

          <>
            <div className="field">
              <div className="control">
                <input
                  className="input input-box"
                  type="text"
                  name="username"
                  placeholder="Enter the registered user email id"
                  value={email}
                  onChange={update}
                  required={true}
                />
              </div>
              <NdButton
                startIcon={<LoadingIcon loading={isLoading} />}
                disabled={isLoading}
                variant="contained"
                onClick={resetPasswordHandler}
                sx={{ padding: "8px 18px", marginTop: "20px" }}
              >
                Confirm
              </NdButton>
            </div>
          </>
        </>
      </div>
    </div>
  );
};

export default ResetPassword;
