import { Button, Card, CardContent, Grid, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import LoadingIcon from "src/Components/Common/LoadingIcon";
import useFetch from "src/Components/Common/useFetch";
import CustomToast from "src/Components/CustomToast";
import { API_ENDPOINTS } from "src/Utils/ApiConstants/ApiUrlConstants";

const DesignationSetting = (props: any) => {
  const errdesignation = useRef<boolean>(false);
  const [isSavingSettings, setIsSavingSettings] = useState(false);

  const bottomRef = useRef<any>();

  useEffect(() => {
    // getListAllDesignation();

    if (props.from === "AddBpUser") {
      addTableRows();
    }

    const newtempListAllDesignation = props.listAllDesignation.map((v: any) => ({ ...v, isError: false }));
    props.setListAllDesignation(newtempListAllDesignation);
    // eslint-disable-next-line
  }, []);

  const saveDesignationSetting = async () => {
    const designationNames: string[] = [];
    let hasDuplicateDesignationNames = false;
    for (const entry of props.listAllDesignation) {
      if (!designationNames.includes(entry.designationName?.trim())) {
        designationNames.push(entry.designationName);
      } else {
        // errdesignation.current = true;
        hasDuplicateDesignationNames = true;
        toast.error(<CustomToast message="Duplicate designation name(s) exist" />);
      }
      for (const property in entry) {
        if (property === "designationName") {
          if (entry[property]?.trim() === "") {
            entry.isError = true;
            errdesignation.current = true;
          } else {
            errdesignation.current = false;
          }
        }
      }
    }

    const hasNAObject = props.listAllDesignation?.some(
      (obj: any) => obj.ledgerRequest === "NA" && obj.balanceConfirmation === "NA" && obj.paymentAdvice === "NA"
    );
    if (hasNAObject && !errdesignation.current) {
      toast.error(<CustomToast message="All 'NA' not possible" />);
    }

    if (!errdesignation.current && !hasDuplicateDesignationNames && !hasNAObject) {
      props.listAllDesignation.forEach((object: any) => {
        delete object.isError;
      });

      setIsSavingSettings(true);
      await useFetch(API_ENDPOINTS.ADD_DESIGNATIONS.url, "POST", {
        failureMessage: API_ENDPOINTS.ADD_DESIGNATIONS.failureMessage,
        showSuccessToast: true,
        data: {
          designations: props.listAllDesignation,
        },
        thenCallBack: () => {
          setIsSavingSettings(false);
          // if (props.from === "AddBpUser") {
          // props.setDesignationNameObj(designationObj);
          // props.setAddDesignationDialogValue(designation);
          props.getListAllDesignation();
          // props.listAllBPContacts();
          // }
        },
        catchCallBack: () => {
          setIsSavingSettings(false);
        },
      });
    }
  };

  const designationChange = (index: number, evnt: any) => {
    const { name, value } = evnt.target;
    const rowsInput = [...props.listAllDesignation];
    rowsInput[index][name] = value;
    props.setListAllDesignation(rowsInput);
  };
  const addTableRows = () => {
    const rowsInput: any = {
      defaultDesignation: false,
      designationId: null,
      designationName: "",
      ledgerRequest: "to",
      balanceConfirmation: "to",
      paymentAdvice: "to",
      // warning: "",
      // paymentStopping: "",
      // NOC: "",
      // disputeManagement: "",
      // confirmConfiguration: "",
      // other: "",
    };
    props.setListAllDesignation([...props.listAllDesignation, rowsInput]);
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
    // window.scrollBy(0, -10);
  };

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <Card style={{ minWidth: "800px" }}>
          <CardContent>
            <Grid className="m_15 mb_40">
              <p className="fs_24 fw_600 text_center">Designation Setting</p>
            </Grid>
            <Grid>
              <div className="mb_25">
                <Button
                  className="theme_btn"
                  onClick={() => {
                    addTableRows();
                  }}
                >
                  Add new designation
                </Button>
              </div>
              <div className="table_container">
                <div className="designationSetting table_fixed">
                  <table>
                    <thead>
                      <tr>
                        <th className="text_center">Designation</th>
                        <th className="text_center">Ledger Request</th>
                        <th className="text_center">Balance confirmation</th>
                        <th className="text_center">Payment Advice</th>
                        {/* <th className="text_center">Warning</th>
                  <th className="text_center">Payment stopping</th>
                  <th className="text_center">NOC</th>
                  <th className="text_center">Dispute management</th>
                  <th className="text_center">Confirm Configuration</th>
                  <th className="text_center">Other</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {props.listAllDesignation?.map((data: any, index: number) => (
                        <tr key={index} style={{ backgroundColor: "#ffffff" }}>
                          <td className="text_center">
                            <TextField
                              disabled={data?.nonEditableUsername}
                              size="small"
                              variant="outlined"
                              required={true}
                              fullWidth={true}
                              label="Enter Designation"
                              name="designationName"
                              error={data?.isError}
                              // helperText={data.isError ? "enter designation" : ""}
                              value={data?.designationName}
                              onChange={(e) => {
                                designationChange(index, e);
                                if (e.target.value.trim() !== "") {
                                  data.isError = false;
                                } else {
                                  data.isError = true;
                                }
                              }}
                            />
                          </td>
                          <td className="text_center">
                            <Select
                              size="small"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={data?.ledgerRequest}
                              name="ledgerRequest"
                              onChange={(e) => {
                                designationChange(index, e);
                              }}
                            >
                              <MenuItem value={"NA"}>NA</MenuItem>
                              <MenuItem value={"to"}>to</MenuItem>
                              <MenuItem value={"cc"}>cc</MenuItem>
                              <MenuItem value={"bcc"}>bcc</MenuItem>
                            </Select>
                          </td>
                          <td className="text_center">
                            <Select
                              size="small"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={data?.balanceConfirmation}
                              name="balanceConfirmation"
                              onChange={(e) => {
                                designationChange(index, e);
                              }}
                            >
                              <MenuItem value={"NA"}>NA</MenuItem>
                              <MenuItem value={"to"}>to</MenuItem>
                              <MenuItem value={"cc"}>cc</MenuItem>
                              <MenuItem value={"bcc"}>bcc</MenuItem>
                            </Select>
                          </td>
                          <td className="text_center">
                            <Select
                              size="small"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={data?.paymentAdvice}
                              name="paymentAdvice"
                              onChange={(e) => {
                                designationChange(index, e);
                              }}
                            >
                              <MenuItem value={"NA"}>NA</MenuItem>
                              <MenuItem value={"to"}>to</MenuItem>
                              <MenuItem value={"cc"}>cc</MenuItem>
                              <MenuItem value={"bcc"}>bcc</MenuItem>
                            </Select>
                          </td>
                          {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.warning}
                        name="warning"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                          {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.paymentStopping}
                        name="paymentStopping"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                          {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.NOC}
                        name="NOC"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                          {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.disputeManagement}
                        name="disputeManagement"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                          {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.confirmConfiguration}
                        name="confirmConfiguration"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                          {/* <td className="text_center">
                      <Select
                        size="small"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={data?.other}
                        name="other"
                        onChange={(e) => {
                          designationChange(index, e);
                        }}
                      >
                        <MenuItem value={""}>-</MenuItem>
                        <MenuItem value={"to"}>to</MenuItem>
                        <MenuItem value={"cc"}>cc</MenuItem>
                        <MenuItem value={"bcc"}>bcc</MenuItem>
                      </Select>
                    </td> */}
                        </tr>
                      ))}
                    </tbody>
                    <div ref={bottomRef} className="mb_80" />
                  </table>
                </div>
              </div>
            </Grid>
            <Grid className="center_align mt_30">
              <div>
                <Button
                  startIcon={<LoadingIcon loading={isSavingSettings} />}
                  disabled={isSavingSettings}
                  className="theme_btn"
                  onClick={() => {
                    saveDesignationSetting();
                  }}
                >
                  Save
                </Button>
              </div>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
};

export default DesignationSetting;
